<template>
  <vue-final-modal
    v-model="isModalVisible"
    class="modal-demo-unavilable"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="emit('close-modal')" />
        <div class="header-main">
          <span class="title">{{ name }}</span>
          <span class="sub-title">{{ identity }}</span>
        </div>
      </div>
    
      <div class="description">
        {{ description }}
      </div>
    
      <div class="modal-demo-unavilable__actions">
        <button-base
          type="gray"
          size="md"
          @click="handleModalAction('signIn')"
        >
          {{ loginButtonLabel }}
        </button-base>
        <button-base
          type="primary"
          size="md"
          @click="handleModalAction('register')"
        >
          <atomic-icon id="user-new" />
          {{ registrationButtonLabel }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>
    
<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  const props = defineProps<{
    isVisible: boolean;
    name?: string;
    identity?: string;
  }>();
  const emit = defineEmits(['close-modal']);
  const isModalVisible = ref(props.isVisible);
  const handleModalAction = (modalType: 'signIn' | 'register') => {
    showModal(modalType);
    emit('close-modal');
  };
  const { showModal } = useLayoutStore();
  const { popupsData, defaultLocalePopupsData } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const loginButtonLabel = computed(() => getContent(popupsData, defaultLocalePopupsData, 'mobileGame.login'));
  const description = computed(() => getContent(popupsData, defaultLocalePopupsData, 'mobileGame.onlyReal'));
  const registrationButtonLabel = computed(() => getContent(popupsData, defaultLocalePopupsData, 'mobileGame.registration'));
</script>
<style src="~/assets/styles/components/modal/demo-unavilable.scss" lang="scss" />
    
    